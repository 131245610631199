import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getNotifications(config) {
    try {
        const response = await httpUtils.get(`${env.API_VERSION}/notifications`, config);
        return response;
    } catch (error) {
        console.error('Notification API Error:', error);
        throw error;
    }
}

export async function markAsRead(id) {
    return httpUtils.post(`${env.API_VERSION}/notifications/${id}/read`);
}

export async function markAsUnread(id) {
    return httpUtils.post(`${env.API_VERSION}/notifications/${id}/unread`);
}

export async function markAllAsRead() {
    return httpUtils.post(`${env.API_VERSION}/notifications/mark-all-read`);
}

export async function deleteNotification(id) {
    return httpUtils.delete(`${env.API_VERSION}/notifications/${id}`);
} 