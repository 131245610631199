import IconInstagram from "@/assets/images/icon-instagram.svg";
import IconLinkedin from "@/assets/images/icon-linkedin.svg";
import IconTwitter from "@/assets/images/icon-twitter.svg";
import IconYoutube from "@/assets/images/icon-youtube.svg";
import Image from "next/legacy/image";
import Link from "next/link";
import navLogoVertical from "../../assets/images/nav-logo-vertical.svg";
import { Button } from "../ui/button";
import { CalendarCheck, Rocket } from "@phosphor-icons/react/dist/ssr";

export default function Navbar() {
  const year = new Date().getFullYear();

  return (
    <div className='relative flex overflow-hidden bg-gradient-to-br to-background from-white/5'>
      <div className='absolute top-0 w-full z-10 h-[0.8px] bg-gradient-to-r from-background via-yellow-1 to-background'></div>
      <div className='container relative'>
        <div className='z-20 flex flex-col items-center justify-between w-full gap-4 py-8 md:flex-row md:items-start border-opacity-30 md:py-12'>
          <Link legacyBehavior href={"/"} passHref>
            <a className='cursor-pointer'>
              <Image
                id='image-logo'
                src={navLogoVertical}
                alt='Lucidity Insights'
              ></Image>
            </a>
          </Link>
          <div className='flex flex-col items-start justify-between w-full gap-8 text-center text-white lg:text-left lg:flex-row md:gap-24 md:justify-center'>
            <div className='flex flex-col w-full gap-3 lg:w-auto'>
              <h3 className='text-lg font-bold'>About</h3>
              <Link legacyBehavior href={"/about"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Company
                </a>
              </Link>
              <Link legacyBehavior href={"/careers"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Careers
                </a>
              </Link>
              <Link legacyBehavior href={"/contact-us"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Contact & Support
                </a>
              </Link>
            </div>
            <div className='flex flex-col w-full gap-3 lg:w-auto'>
              <h3 className='text-lg font-bold'>Legal</h3>
              <Link legacyBehavior href={"/privacy-policy"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Privacy Policy
                </a>
              </Link>
              {/* <Link legacyBehavior href={"/cancellation-policy"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Cancellation Policy
                </a>
              </Link> */}
              <Link legacyBehavior href={"/terms-of-service"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Terms of Service
                </a>
              </Link>
              <Link legacyBehavior href={"/dont-sell"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Do Not Sell My Info
                </a>
              </Link>
              <Link legacyBehavior href={"/attribution-instructions"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  Attribution Instructions
                </a>
              </Link>
              {/* <Link legacyBehavior href={"/faq"} passHref>
                <a className='font-medium duration-300 ease-out opacity-60 hover:opacity-100'>
                  FAQ
                </a>
              </Link> */}
              {/* <Link legacyBehavior href={"#"} passHref>
                <a className="font-medium duration-300 ease-out opacity-60 hover:opacity-100">
                  Our Ads
                  </a>
              </Link> */}
            </div>
            <div className='flex flex-col w-full gap-3 lg:w-auto'>
              <Link
                href='https://calendly.com/lucy-viqk/free-onboarding-demo'
                target='_blank'
                className='inline-flex items-center text-white rounded-md justify-center py-2.5 px-5 text-sm font-medium bg-gradient-to-l from-red-500 to-purple-600'
              >
                <CalendarCheck weight='bold' className='mr-2' />
                Book a free demo with us
              </Link>
              <Link
                href='https://forms.gle/djJFoYZ7pUtoewNy6'
                target='_blank'
                className='inline-flex items-center text-white rounded-md justify-center py-2.5 px-5 text-sm font-medium bg-gradient-to-l from-red-500 to-yellow-600'
              >
                <Rocket weight='bold' className='mr-2' />
                Feature my Startup Story
              </Link>
            </div>
          </div>
          <div className='flex flex-col items-center mt-4 md:w-1/4 md:items-start md:mt-0'>
            <h3 className='hidden mb-4 font-medium text-white opacity-60 md:block'>
              Social Media
            </h3>
            <div className='flex items-center justify-start gap-4'>
              <Link
                legacyBehavior
                passHref
                href={"https://www.linkedin.com/company/lucidity-insights/"}
              >
                <a className='w-8 h-8' target={"_blank"}>
                  <Image src={IconLinkedin} alt='Lucidity Insights'></Image>
                </a>
              </Link>
              <Link
                legacyBehavior
                passHref
                href={"https://twitter.com/lucidityinsight"}
              >
                <a className='w-8 h-8' target={"_blank"}>
                  <Image src={IconTwitter} alt='Lucidity Insights'></Image>
                </a>
              </Link>
              <Link
                legacyBehavior
                passHref
                href={"https://www.instagram.com/lucidityinsights/"}
              >
                <a className='w-8 h-8' target={"_blank"}>
                  <Image src={IconInstagram} alt='Lucidity Insights'></Image>
                </a>
              </Link>
              <Link
                legacyBehavior
                passHref
                href={"https://www.youtube.com/@lucidityinsights"}
              >
                <a className='w-8 h-8' target={"_blank"}>
                  <Image src={IconYoutube} alt='Lucidity Insights'></Image>
                </a>
              </Link>
              {/* <Link legacyBehavior passHref href={"#"}>
                <a className='w-8 h-8'>
                  <Image src={IconFacebook} alt='Lucidity Insights'></Image>
                </a>
              </Link> */}
            </div>
            <span className='block w-full mt-8 text-xs text-center text-white md:text-left opacity-60'>
              © {year} Lucidity Insights.
            </span>
          </div>
        </div>
      </div>
      {/* <div className='bottom-0 right-0 hidden transform translate-y-16 md:block shape-footer'></div> */}
    </div>
  );
}
