import { CircleNotch, X } from "@phosphor-icons/react";
import { useEffect } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

import { registerPopup, subscribePopup } from "@/Atoms";
import useSubscribe from "@/utils/useSubscribe";
import { useAtom } from "jotai";
import { useReCaptcha } from "next-recaptcha-v3";

const Subscribe = () => {
  const [openSubscribePopup, setOpenSubscribePopup] = useAtom(subscribePopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const { executeRecaptcha } = useReCaptcha();
  const { handleSubscribe, loadingSubscribe } = useSubscribe(
    executeRecaptcha,
    () => {
      const timer = setTimeout(() => {
        setOpenSubscribePopup(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  );

  useEffect(() => {
    if (
      localStorage.getItem("lc_already_open_newsletter") === "true" &&
      !openRegisterPopup
    ) {
      localStorage.setItem("lc_already_open_newsletter", "false");
    }
  }, [openRegisterPopup]);

  return (
    <>
      {openSubscribePopup && (
        <div className='fixed z-50 px-5 py-4 overflow-hidden bg-white rounded-lg shadow-xl text-background bottom-0 inset-x-0 md:bottom-5 md:left-5 w-full md:w-[400px]'>
          <div className='relative'>
            <div className='absolute top-0 block -right-2'>
              <button
                type='button'
                className='text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={() => setOpenSubscribePopup(false)}
              >
                <span className='sr-only'>Close</span>
                <X className='w-5 h-5' aria-hidden='true' />
              </button>
            </div>
            <p className='text-xl font-semibold lg:text-2xl font-display'>
              Subscribe to Our Newsletter
            </p>
            <p className='mt-1.5 text-xs opacity-60'>
              to keep up to date with Lucidity Insights&apos; latest content
            </p>
            <form
              disabled={loadingSubscribe}
              onSubmit={handleSubscribe}
              className='grid grid-cols-12 gap-4 mt-5'
            >
              <div className='col-span-6 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='first_name'>
                  First Name
                </Label>
                <Input
                  type='text'
                  id='first_name'
                  name='first_name'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='col-span-6 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='last_name'>
                  Last Name
                </Label>
                <Input
                  type='text'
                  id='last_name'
                  name='last_name'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='col-span-12 space-y-1 text-left'>
                <Label className='text-xs' htmlFor='email'>
                  Email Address
                </Label>
                <Input
                  type='email'
                  id='email'
                  name='email'
                  required
                  className='h-auto border-gray-300 shadow-sm bg-white/5 hover:bg-white/10 focus-visible:ring-0'
                  disabled={loadingSubscribe}
                />
              </div>
              <div className='flex flex-col items-center col-span-12 gap-4'>
                <Button
                  type='submit'
                  variant='secondary'
                  className='w-full'
                  disabled={loadingSubscribe}
                >
                  {loadingSubscribe && (
                    <CircleNotch
                      weight='bold'
                      className='mr-2 text-lg animate-spin'
                    />
                  )}
                  {loadingSubscribe
                    ? "Processing..."
                    : "Subscribe to Our Weekly Newsletter"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscribe;
