const availablePrices = {
  AED: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjZMqTGP5q",
    "Content Access": "price_1NzJ8rGlqmUCDtkjsuCyXOPC",
    "VC Registration": "price_1Paq6zGlqmUCDtkjvDeDcFd9",
    "Angel Investor Registration": "price_1QWuNzGlqmUCDtkjS2rCXD97",
  },
  IDR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjnb6hPieV",
    "Content Access": "price_1NzKaaGlqmUCDtkj06M2wlAe",
    "VC Registration": "price_1Paq6zGlqmUCDtkjTwvClA5l",
    "Angel Investor Registration": "price_1QWuQdGlqmUCDtkjuVjWDiyU",
  },
  USD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkj3m5B89Ra",
    "Content Access": "price_1NzJ9iGlqmUCDtkj0yn0nP1D",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx",
    "Angel Investor Registration": "price_1QWuPFGlqmUCDtkjiHQI5PNr",
  },
  JOD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjdjdsx9nN",
    "Content Access": "price_1NzJCVGlqmUCDtkjZdNe84MF",
    "VC Registration": "price_1PsYnjGlqmUCDtkjAepFuciG",
    "Angel Investor Registration": "price_1QWuU5GlqmUCDtkjjOLkjXEC",
  },
  SAR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjwxJ45v4J",
    "Content Access": "price_1NzJAqGlqmUCDtkjlSqHoKCx",
    "VC Registration": "price_1Paq6zGlqmUCDtkjQo59LUYf",
    "Angel Investor Registration": "price_1QWuOjGlqmUCDtkj8BsRWZEr",
  },
  EGP: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjfMlFm5JI",
    "Content Access": "price_1NzJBpGlqmUCDtkjObBXdLmI",
    "VC Registration": "price_1PsYmnGlqmUCDtkjstXU2AR5",
    "Angel Investor Registration": "price_1QWuSXGlqmUCDtkjjC6UnDKx",
  },
};

export default availablePrices;
