import DOMPurify from "isomorphic-dompurify";
import parse from "html-react-parser";

export const CONTENT_TYPES = {
  VIDEOS: "Videos",
  SPECIAL_REPORTS: "Special Reports",
  SPOTLIGHTS: "Spotlights",
  INFOBYTES: "Infobytes",
  ARTICLES: "Articles",
  NEWS: "News",
  OPINIONS: "Opinions",
  PODCAST_EPISODES: "Podcast Episodes",
  VIDEOS_AND_PODCAST_EPISODES: "Videos And Podcast Episodes",
};

export const CONTENT_TYPES_SLUG = {
  "Videos": "videos",
  "Special Reports": "reports",
  "Spotlights": "spotlights",
  "Infobytes": "infobytes",
  "Articles": "articles",
  "News": "news",
  "Opinions": "opinions",
  "Podcast Episodes": "/podcasts-masterclasses"
};

export function getContentTypeUuid(name, content_types) {
  if (content_types.length == 0) return null;

  return content_types.find(
    (content_type) => content_type.name.toLowerCase() == name.toLowerCase()
  );
}

export function cleanHTML(html) {
  const regex = /<a\s+href="(.*?)">/g;
  const output = html.replace(
    regex,
    '<a target="_blank" rel="noopener noreferrer" href="$1">'
  );

  const result = parse(
    DOMPurify.sanitize(output, {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['target', 'allowfullscreen', 'frameborder', 'allow'],
      ADD_TAGS: ['iframe'],
      ALLOWED_TAGS: ['iframe', 'div', 'p', 'a', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'strong', 'em', 'br'],
      ALLOWED_ATTR: ['class', 'href', 'target', 'rel', 'src', 'title', 'allowfullscreen', 'frameborder', 'allow']
    })
  );
  return result;
}
