import GoogleG from "@/assets/images/google-g-logo.svg";
import { fetchGoogleAuthDetail } from "@/services/api/User";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { setCookies } from "cookies-next";
import { useRouter } from "next/router";
import { Button } from "../ui/button";

function GoogleSignInButton({
  label = "Sign In with Google",
  disabled = false,
} = {}) {
  const [isFetching, setIsFetching] = useState(false); // Tracks API call status
  const router = useRouter();

  const processGoogle = async () => {
    try {
      setIsFetching(true); // Show a loading state (if desired)
      const response = await fetchGoogleAuthDetail();

      if (response?.data?.url) {
        if (router.asPath) {
          setCookies("google-url-redirect-to", router.asPath);
        }

        // Redirect to Google Sign-In URL
        const a = document.createElement("a");
        a.href = response.data.url;
        a.click();
      } else {
        console.error("Failed to retrieve Google Sign-In URL.");
      }
    } catch (error) {
      console.error("Error fetching Google Sign-In URL:", error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Button
      type='button'
      className='w-full border border-background/10 text-background/80'
      onClick={processGoogle}
      disabled={disabled || isFetching} // Disable button if fetching
    >
      <div className='relative h-3.5 mr-2 aspect-square'>
        <Image src={GoogleG} alt='Google' layout='fill' />
      </div>
      {isFetching ? "Loading..." : label} {/* Show loading state */}
    </Button>
  );
}

export default GoogleSignInButton;
