const availablePrices = {
  AED: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjZMqTGP5q", // not used
    "Content Access": "price_1NzJ6xGlqmUCDtkjVEgCuENF",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
  IDR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkj3m5B89Ra", // not used
    "Content Access": "price_1NzKa5GlqmUCDtkjvhFZ3Lva",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used,
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
  USD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkj3m5B89Ra", // not used
    "Content Access": "price_1NzJ9LGlqmUCDtkj8VZPPnlj",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
  JOD: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjdjdsx9nN", // not used
    "Content Access": "price_1NzJC9GlqmUCDtkjh3M9aSOz",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
  SAR: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjwxJ45v4J", // not used
    "Content Access": "price_1NzJA5GlqmUCDtkjBk3KfMS8",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
  EGP: {
    "Startup Registration": "price_1NlCA9GlqmUCDtkjfMlFm5JI", // not used
    "Content Access": "price_1NzJBVGlqmUCDtkjY1MiASGE",
    "VC Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
    "Angel Investor Registration": "price_1Paq6zGlqmUCDtkj4o4cNLLx", // not used
  },
};

// UAE
// Monthly:price_1Ny9QrGlqmUCDtkj5ZIKlcZD
// Yearly: price_1Ny9RUGlqmUCDtkjWKjswYRk

// USD: 
// Monthly: price_1Ny9RpGlqmUCDtkjsVUrLe3l
// Yearly: price_1Ny9SZGlqmUCDtkjtDAWacNF

// KSA:
// Monthly: price_1Ny9TUGlqmUCDtkjMVMIRlnH
// Yearly: price_1Ny9TqGlqmUCDtkj38V6znqg

// Egypt:
// Monthly: price_1Ny9UPGlqmUCDtkjFA7bwNcy
// Yearly: price_1Ny9UmGlqmUCDtkjurbE9Px7

// Jordan:
// Monthly:price_1Ny9W0GlqmUCDtkj4q6Hp5Y0
// Yearly: price_1Ny9WWGlqmUCDtkjEOh1Ul0c

export default availablePrices;
