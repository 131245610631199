import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { verifyCaptcha } from "@/services/api/Master";
import { subscribe } from "@/services/api/Newsletter";

const useSubscribe = (executeRecaptcha, onSuccess) => {
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);

  const handleSubscribe = useCallback(
    async (event) => {
      event.preventDefault();
      setLoadingSubscribe(true);
      console.log("handleSubscribe");
      try {
        const email = event.target.email.value;
        const first_name = event.target.first_name.value;
        const last_name = event.target.last_name.value;
        const thisYear = new Date().getFullYear();

        const token = await executeRecaptcha("form_subscribe");
        const response = await verifyCaptcha(token);

        if (response.data.score > 0.5) {
          const payload = {
            email,
            tags: ["Customer", String(thisYear), "Web"],
            first_name,
            last_name,
          };

          await subscribe(payload);
          toast.success("You are successfully subscribed to our newsletter!");
          event.target.reset();
          onSuccess();
        }
      } catch (error) {
        if (error.response?.data?.message === "User is already subscribed.") {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } finally {
        setLoadingSubscribe(false);
      }
    },
    [executeRecaptcha, onSuccess]
  );

  return { handleSubscribe, loadingSubscribe };
};

export default useSubscribe;
